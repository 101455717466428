import { ActivityIndicator, Text, useDripsyTheme, View } from 'dripsy'
import React, { ComponentProps } from 'react'
import { Platform, StyleSheet } from 'react-native'
import type { DripsyTheme } from '@beatgig/theme'
import Ionicons, { IconsBaseProps } from '@beatgig/design/components/ionicons'
import Press from '../press'

// const Ionicons = () => <></>

type Props = {
  children?: React.ReactNode | string
  variant?: keyof DripsyTheme['buttons']
  sx?: ComponentProps<typeof View>['sx']
  labelSx?: ComponentProps<typeof View>['sx']
  isText?: boolean
  onPress?: ComponentProps<typeof Press>['onPress']
  labelProps?: ComponentProps<typeof Text>
  touchableProps?: ComponentProps<typeof Press>
  iconLeft?: {
    name: IconsBaseProps['name']
    size?: number
    color?: string
  }
  iconRight?: ComponentProps<typeof Ionicons>
  loading?: boolean
  success?: boolean
  disabled?: boolean
  loadingColor?: string
} & ComponentProps<typeof View>

const textKeys = [
  'color',
  'fontSize',
  'fontWeight',
  'textTransform',
  'textAlign',
]

const Button = React.forwardRef(function Button(props: Props, ref?: any) {
  const {
    children = null,
    variant = 'primary',
    sx = {},
    labelSx = {},
    isText = true,
    onPress,
    labelProps,
    touchableProps,
    iconLeft = {},
    loading = false,
    success = false,
    disabled = false,
    hitSlop = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
    loadingColor,
    iconRight = null,
    ...viewProps
  } = props

  const { colors } = useDripsyTheme().theme

  const resolvedSx = { ...sx }
  const resolvedLabelSx: Props['labelSx'] = { ...labelSx }
  const resolvedIconLeft: Props['iconLeft'] = {
    color: 'background',
    size: 20,
    ...(iconLeft as any),
  }

  textKeys.forEach((key) => {
    if ((resolvedSx as any)[key]) {
      // if we style this in sx by accident, move it to the label
      ;(resolvedLabelSx as any)[key] = (sx as any)[key]
      delete (resolvedSx as any)[key]
    }
  })

  if ((resolvedSx as any).color) {
    // if we style this in sx by accident, move it to the label
    ;(resolvedLabelSx as any).color = (sx as any).color
    delete (resolvedSx as any).color
  }
  if ((resolvedSx as any).fontSize) {
    // if we style this in sx by accident, move it to the label
    ;(resolvedLabelSx as any).fontSize = (sx as any).fontSize
    delete (resolvedSx as any).fontSize
  }
  if ((resolvedSx as any).textTransform) {
    // if we style this in sx by accident, move it to the label
    ;(resolvedLabelSx as any).textTransform = (sx as any).textTransform
    delete (resolvedSx as any).textTransform
  }
  const { theme } = useDripsyTheme()

  // @ts-ignore
  const { label: labelStyle = {}, ...variantStyle } = theme.buttons[
    variant
  ] ?? {
    label: {},
  }

  if (resolvedIconLeft?.name && resolvedIconLeft.color) {
    resolvedIconLeft.color = (theme.colors?.[resolvedIconLeft.color] ||
      resolvedIconLeft.color) as string
  }
  const loaderColor =
    loadingColor ??
    (resolvedLabelSx as any)?.color ??
    (resolvedIconLeft as any)?.color ??
    labelStyle?.color ??
    'background'

  if (loading) {
    // @ts-ignore
    resolvedLabelSx.color = 'transparent'
    // @ts-ignore
    resolvedIconLeft.color = 'transparent'
  }

  return (
    <Press
      disabled={loading || disabled}
      {...touchableProps}
      onPress={onPress}
      ref={ref}
      hitSlop={hitSlop}
      accessibilityRole="button"
    >
      {({ hovered }) => (
        <View
          {...viewProps}
          sx={{
            flexDirection: iconLeft ? 'row' : undefined,
            alignItems: 'center',
            justifyContent: 'center',
            ...Platform.select({
              web: {
                transitionProperty: 'transform',
                transitionDuration: '0.25s',
                transitionTimingFunction: 'ease-in-out',
                transform: [
                  {
                    scale: hovered && (disabled !== true || onPress) ? 1.04 : 1,
                  },
                ],
              },
              default: undefined,
            }),
            ...variantStyle,
            ...resolvedSx,
          }}
        >
          {!!resolvedIconLeft?.name && (
            <View sx={{ mr: children ? 2 : 0 }}>
              <Ionicons selectable={false} {...resolvedIconLeft} />
            </View>
          )}
          {loading && (
            <View
              sx={{
                ...StyleSheet.absoluteFillObject,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator
                color={theme.colors?.[loaderColor] as string}
              />
            </View>
          )}
          {isText && children ? (
            <Text
              selectable={false}
              {...labelProps}
              sx={{
                textAlign: 'center',
                ...labelStyle,
                ...resolvedLabelSx,
              }}
            >
              {children}
            </Text>
          ) : (
            children
          )}
          {!!iconRight && (
            <Ionicons
              size={20}
              color={
                (resolvedLabelSx as any).color ??
                labelStyle.color ??
                'background'
              }
              sx={{ ml: children ? 2 : 0 }}
              {...iconRight}
            />
          )}
        </View>
      )}
    </Press>
  )
})

export default Button
